.beratungsweiche__communication-module {
    display: block;
    text-align: center;

    margin: 0px !important;

    /*hide gray overlay on press for iOS*/
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

@media (min-width: 768px) {
    .beratungsweiche__communication-module {
        padding: 40px 24px !important;
        height: 309px;
    }
}

.beratungsweiche__communication-module__icon {
    fill: #000000 !important;

    width: 48px !important;
    height: 48px !important;
}

@media (min-width: 768px) {
    .beratungsweiche__communication-module__icon {
        width: 72px !important;
        height: 72px !important;
    }
}

.beratungsweiche__communication-module__title {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    line-height: 24px;

    margin-top: 12px;
}

@media (min-width: 768px) {
    .beratungsweiche__communication-module__title {
        font-size: 18px;
    }
}

.beratungsweiche__communication-module__text {
    font-size: 14px;
    color: #000000;
    line-height: 20px;

    margin-top: 16px;
}

@media (min-width: 768px) {
    .beratungsweiche__communication-module__text {
        font-size: 16px;
        line-height: 24px;
    }
}

.beratungsweiche__communication-module__buttonwrapper {
    margin-top: 32px;
    margin-bottom: 14px;
}

.beratungsweiche__communication-module_button {

}

.beratungsweiche__communication-module__button > .ee_button__wrapper {
    min-height: 20px !important;
}