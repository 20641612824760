#ee-details-no-padding > .ee_details__summary {
    padding: 0;
    background-color: transparent;
}

.ee-details__email-link {
    display: inline-block;
    word-break: break-all;
    padding: 0%;
}

.agentdetail__wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 0.9em;
    width: 95%;  /*IE needs this*/
    min-height: 23em; /*IE needs this*/
}

.agentdetail__upperSection {
    display: flex;
    flex-direction: column;
    flex: 1 1 6.6em;
    justify-content: center;
    max-height: 150px;
}

.agentdetail__imageContainer {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    height: 70px;
    width: 70px;
    left: 10px;
    top: 10px;
}