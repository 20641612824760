.beratungsweiche__popup-panel {
    align-items: center;
    display: flex;
    flex-direction: column;
    left: 0px;
    position: absolute;
    width: 100%;
    padding-bottom: 20px !important;
    z-index: 2;
}

.beratungsweiche__innerCard-div {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 auto;
    width: 260px;
}

.beratungsweiche__start-of-line {
    align-self: flex-start;
    margin-bottom: 0px;
    flex: 0 1 auto;
}

.beratungsweiche__layout-box {
    minWidth: 400px;
}

.beratungsweiche__card {
    width: 320px;
    min-height: 340px;
}

.beratungsweiche__card-right {

}

@media (min-width: 912px) {
    .beratungsweiche__card-right {
        margin-left: 40px;
    }
}

.beratungsweiche__telefon-div {
    display: flex;
    margin-top: 5px;
}