.beratungsweiche__modal-agent-detail-step-container {
    margin-top: 10px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 2fr;
    grid-template-columns: 1fr 2fr;
    -ms-grid-rows: 6em 1fr;
    grid-template-rows: 6em 1fr;
    grid-column-gap: 10px;
    justify-items: baseline;
    min-height: 28em;
}

.beratungsweiche__modal-agent-detail-step-agentDetails {
    -ms-grid-column: 1;
    grid-column: 1 / 1;
    grid-row: 1 / span 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    max-width: 320px;
}

.beratungsweiche__modal-agent-detail-step-agentIntroduction {
    line-height: 1.6em;
    -ms-grid-column: 2;
    grid-column: 2 / 2;
    -ms-grid-row: 1;
    grid-row: 1 / 1;
    width: 95%;
}

.beratungsweiche__modal-agent-detail-step-callToAction {
    -ms-grid-column: 2;
    grid-column: 2 / 2;
    -ms-grid-row: 2;
    grid-row: 2 / 2;
}

.beratungsweiche__modal-agent-detail-step-container p {
    max-width: 95%;
}

@media (max-width: 768px) {
    .beratungsweiche__modal-agent-detail-step-container {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;
        justify-items: center;
    }

    .beratungsweiche__modal-agent-detail-step-agentIntroduction {
        line-height: 1.6em;
        -ms-grid-column: 1;
        grid-column: 1 / 1;
        -ms-grid-row: 1;
        grid-row: 1 / 1;
    }

    .beratungsweiche__modal-agent-detail-step-agentDetails {
        -ms-grid-column: 1;
        grid-column: 1 / 1;
        -ms-grid-row: 2;
        grid-row: 2 / 2;
    }

    .beratungsweiche__modal-agent-detail-step-callToAction {
        -ms-grid-column: 1;
        grid-column: 1 / 1;
        -ms-grid-row: 3;
        grid-row: 3 / 3;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-top: 5px;
    }

    .beratungsweiche__modal-agent-detail-step-callToAction Form {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }

    .beratungsweiche__modal-agent-detail-step-callToAction p {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }
}

@media (min-width: 768px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .beratungsweiche__modal-agent-detail-step-container {
        height: 30em;
    }
}