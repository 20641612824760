.cardlayoutwrapper__container {
  display: flex;
  flex-direction: column;
  min-height: 325px;
}

.cardlayoutwrapper__header {
  display: flex;
  justify-content: center;
}

.cardlayoutwrapper__sub-header {
  align-self: center;
}

.cardlayoutwrapper__picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 12;
  min-height: 8em;
}

.cardlayoutwrapper__picture {
  max-height: 100%;
  max-width: 100%;
}

.cardlayoutwrapper__body-element-container {
  align-self: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 21em;
  min-width: 99%;
  min-height: 19em;
}

.cardlayoutwrapper__body-element-container-centered {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 5.5em;
}

.cardlayoutwrapper__button-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 10px;
}

.cardlayoutwrapper__button-container > .ee_button--primary {
  background-color: #8e0038 !important;
}
.cardlayoutwrapper__button-container > .ee_button--primary:hover {
  background-color: #71022e !important;
}
